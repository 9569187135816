import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General', path: '/general/home', icon: 'pe-7s-home', type: 'sub', active: true, children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {path: '/general/home', title: 'Home', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Branches', path: '/branches/home', icon: 'fas fa-code-branch', type: 'sub', active: false, children:
        [
          {
            title: 'Branches', type: 'sub', active: true, children: [
              {path: '/branches/home', title: 'Home', type: 'link'},
              {path: '/branches/add', title: 'Add Branch', type: 'link'},
              {path: '/branches/list', title: 'List Branches', type: 'link'},
              {path: '/branches/share-percentage', title: 'Share Percentage', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Students', path: '/students/home', icon: 'fas fa-user-graduate', type: 'sub', active: false, children:
        [
          {
            title: 'Student', type: 'sub', active: true, children: [
              {path: '/students/home', title: 'Home', type: 'link'},
              {path: '/students/add-student', title: 'Add Student', type: 'link'},
              {path: '/students/list-student', title: 'List Students', type: 'link'},
              {path: '/students/re-register-student', title: 'Re-Register Students', type: 'link'},
              /*{path: '/students/process-students', title: 'Process Students', type: 'link'},*/
              {path: '/students/transfer-students', title: 'Transfer Students', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Exams', path: '/exams/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exams', type: 'sub', active: true, children: [
              {path: '/exams/home', title: 'Home', type: 'link'},
              {path: '/exams/exam-time-table', title: 'Exam Time Table', type: 'link'},
              {path: '/exams/ia-aw-marks', title: 'IA-AW Marks', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Results', path: '/results/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Results', type: 'sub', active: true, children: [
              {path: '/results/home', title: 'Home', type: 'link'},
              {path: '/results/student-result', title: 'Students\' Result', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Accounts', path: '/accounts/home', icon: 'fas fa-credit-card', type: 'sub', active: false, children:
        [
          {
            title: 'Accounts', type: 'sub', active: true, children: [
              {path: '/accounts/home', title: 'Home', type: 'link'},
              {path: '/accounts/transactions', title: 'Transactions', type: 'link'},
              {path: '/accounts/receipts', title: 'Receipts', type: 'link'},
              {path: '/accounts/add-money', title: 'Add Money', type: 'link'},
              {path: '/accounts/branch-reports', title: 'Branch Report', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Support', path: '/support/home', icon: 'pe-7s-headphones', type: 'sub', active: false, children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {path: '/support/home', title: 'Home', type: 'link'},
              {path: '/support/admission-tickets', title: 'Admission Tickets', type: 'link'},
              {path: '/support/re-registration-tickets', title: 'Re-Registration Tickets', type: 'link'},
              {path: '/support/examination-tickets', title: 'Examination Tickets', type: 'link'},
              {path: '/support/marksheet-tickets', title: 'Marksheet Tickets', type: 'link'},
              {path: '/support/transcript-tickets', title: 'Transcript Tickets', type: 'link'},
              {path: '/support/revaluation-tickets', title: 'Revalution Tickets', type: 'link'},
              {path: '/support/migration-tickets', title: 'Migration Tickets', type: 'link'},
              {path: '/support/original-degree-tickets', title: 'Original Degree Tickets', type: 'link'},
              {path: '/support/e-learning-tickets', title: 'E-Learning Tickets', type: 'link'},
              {path: '/support/other-tickets', title: 'Other Tickets', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Notify', path: '/notify/home', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notify', type: 'sub', active: true, children: [
              {path: '/notify/home', title: 'Home', type: 'link'},
              {path: '/notify/add', title: 'Notify Branches', type: 'link'},
            ]
          }
        ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
